<template>
  <v-col md="7" sm="12" xs="12">
    <v-row>
      <v-col
        cols="12"
        sm="12"
        xs="12"
        md="2"
      >
        <v-text-field
          v-model="editedItem.ruc"
          outlined
          dense
          ref="ruc"
          label="RUC"
          @input="$emit('ruc', editedItem.ruc)"
          :counter="11"
          maxlength="11"
          :rules="nameRules"
          :error-messages="errors.get('ruc')"
          @keydown="errors.clear('ruc')"
          v-on:keyup.enter="focusRazonSocial()"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        xs="12"
        md="4"
      >
        <v-text-field
          v-model="editedItem.businessName"
          outlined
          dense
          v-uppercase
          label="Razón social"
          ref="razon"
          v-on:keyup.enter="focusDireccion()"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        xs="12"
        md="5"
      >
        <v-text-field
          v-model="editedItem.direction"
          outlined
          dense
          v-uppercase
          label="Dirección"
          ref="direccion"
          v-on:keyup.enter="focusCelular()"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        sm="12"
        xs="12"
        md="2"
      >
        <v-text-field
          v-model="editedItem.phone"
          outlined
          dense
          label="Celular"
          ref="celular"
          v-on:keyup.enter="focusCorreo()"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        xs="12"
        md="4"
      >
        <v-text-field
          v-model="editedItem.email"
          outlined
          dense
          v-uppercase
          label="Correo electrónico"
          ref="correo"
          v-on:keyup.enter="focusServicio()"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        xs="12"
        md="5"
      >
        <v-text-field
          v-model="editedItem.supplier"
          outlined
          dense
          v-uppercase
          label="Servicio"
          ref="servicio"
          v-on:keyup.enter="focusBuscarProducto()"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import {Errors} from "../../plugins/errors";
export default {
  name: "FormLegal",
  props: ['editedItem'],

  data () {
    return {
      nameRules: [
        v => (v && v.length === 11) || '11 dígitos',
      ],
      errors: new Errors(),
    }
  },
  methods:{
    focusRazonSocial() {
      this.$refs.razon.focus()
    },
    focusDireccion() {
      this.$refs.direccion.focus()
    },
    focusCelular() {
      this.$refs.celular.focus()
    },
    focusCorreo() {
      this.$refs.correo.focus()
    },
    focusServicio() {
      this.$refs.servicio.focus()
    },
    focusBuscarProducto() {
      this.$refs.nombre.focus()
    },
  }
}
</script>

<style scoped>
.basil {
  background-color: #FFFBE6 !important;
}
.basil--text {
  color: #356859 !important;
}
.inputs-container,
.col-12 {
  padding-top: 0;
  padding-bottom: 0;
}
.theme--light.v-input {
  padding-top: 0;
}
.v-input__slot {
  margin-bottom: 0;
}
</style>