<template>
  <v-col md="7" sm="12" xs="12">
    <v-row>
      <v-col
        cols="12"
        sm="12"
        xs="12"
        md="2"
      >
        <v-text-field
          v-model="editedItem.dni"
          outlined
          dense
          label="DNI"
          @input="$emit('dni', editedItem.dni)"
          :counter="8"
          maxlength="8"
          :rules="nameRules"
          v-on:keyup.enter="focusNombre()"
          :error-messages="errors.get('dni')"
          @keydown="errors.clear('dni')"

        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        xs="12"
        md="4"
      >
        <v-text-field
          v-model="editedItem.firstName"
          outlined
          dense
          v-uppercase
          label="Nombres"
          ref="nombre"
          v-on:keyup.enter="focusApellidos()"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        xs="12"
        md="5"
      >
        <v-text-field
          v-model="editedItem.lastName"
          outlined
          dense
          v-uppercase
          label="Apellidos"
          ref="apellidos"
          v-on:keyup.enter="focusDireccion()"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        sm="12"
        xs="12"
        md="5"
      >
        <v-text-field
          v-model="editedItem.direction"
          outlined
          dense
          label="Dirección"
          ref="direccion"
          v-on:keyup.enter="focusCorreo()"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        xs="12"
        md="4"
      >
        <v-text-field
          v-model="editedItem.email"
          outlined
          dense
          v-uppercase
          label="Correo electrónico"
          ref="correo"
          v-on:keyup.enter="focusCelular()"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        xs="12"
        md="2"
      >
        <v-text-field
          v-model="editedItem.phone"
          outlined
          dense
          v-uppercase
          label="Celular"
          ref="celular"
          v-on:keyup.enter="focusBuscarProducto()"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import {Errors} from "../../plugins/errors";
export default {
  name: "FormNatural",
  props: ['editedItem'],

  data () {
    return {
      nameRules: [
        v => (v && v.length === 8) || '08 dígitos',
      ],
      errors: new Errors(),
    }
  },
  methods: {
    focusNombre() {
      this.$refs.nombre.focus()
    },
    focusApellidos() {
      this.$refs.apellidos.focus()
    },
    focusDireccion() {
      this.$refs.direccion.focus()
    },
    focusCorreo() {
      this.$refs.correo.focus()
    },
    focusCelular() {
      this.$refs.celular.focus()
    },
    focusBuscarProducto() {
      this.$refs.buscarProducto.focus()
    },
  }
}
</script>

<style scoped>
.basil {
  background-color: #FFFBE6 !important;
}
.basil--text {
  color: #356859 !important;
}
.inputs-container,
.col-12 {
  padding-top: 0;
  padding-bottom: 0;
}
.theme--light.v-input {
  padding-top: 0;
}
.v-input__slot {
  margin-bottom: 0;
}
</style>