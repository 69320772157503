<template>
  <v-container>
    <v-card color="grey lighten-5" elevation="0">
      <v-card-title>
        <h3 class="font-weight-black display-0 basil--text">
          VENTAS
        </h3>
      </v-card-title>
    </v-card><br>
    <v-dialog
      v-model="dialog"
      scrollable
      persistent
      max-width="1500px"
    >
      <v-card>
        <v-toolbar
          color="success"
          dark
          elevation="0"
        >EDITAR VENTA</v-toolbar>
        <v-card-text class="pt-6 mx-auto mb-3">
          <v-row no-gutters>
          <FormNatural
            v-if="editedItem.type_ticket_invoice_id === 2 || editedItem.type_ticket_invoice_id === 3"
            :editedItem="editedItem.customer.person"
            @dni="changeDni"
          />
          <FormLegal
            v-if="editedItem.type_ticket_invoice_id === 1 || editedItem.type_ticket_invoice_id === 4"
            :editedItem="editedItem.customer.person"
            @ruc="changeRuc"
          />
          <v-col md="5" sm="12" xs="12">
            <v-row>
              <v-col
                cols="12"
                sm="12"
                xs="12"
                md="4"
              >
                <v-autocomplete
                  v-model="editedItem.type_ticket_invoice_id"
                  :items="listSales"
                  item-text="name"
                  item-value="id"
                  clearable
                  outlined
                  dense
                  label="Tipo compra"
                  :error-messages="errors.get('type_ticket_invoice_id')"
                  @keydown="errors.clear('type_ticket_invoice_id')"
                ></v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                xs="12"
                md="3"
              >
                <v-autocomplete
                  v-model="editedItem.type_buy_id"
                  :items="listBuys"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                  label="Forma de pago"
                ></v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="3"
              >
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="editedItem.date"
                  transition="scale-transition"
                  offset-y
                  disabled
                  outlined
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="editedItem.date"
                      label="Fecha compra"
                      readonly
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="editedItem.date"
                    no-title
                    scrollable
                    color="green lighten-1"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="success"
                      @click="menu = false"
                    >
                      Cancelar
                    </v-btn>
                    <v-btn
                      text
                      color="success"
                      @click="$refs.menu.save(editedItem.date)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                xs="12"
                md="2"
              >
                <v-text-field
                  v-model="editedItem.customer.points"
                  outlined
                  dense
                  label="Puntos"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="4"
              >
                <v-checkbox
                  v-model="editedItem.igv"
                  label="IGV"
                  color="success"
                  value="success"
                  hide-details
                  :true-value="1"
                  :false-value="0"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-col>
          </v-row>
          <TableEditSale
            v-show="showTableSale"
            :sales="editedItem"
            :messages="messages"
            @remove="remove"
          />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success darken-1"
            text
            @click="close"
          >
            Cancelar
          </v-btn>
          <!--<v-btn
            color="success darken-1"
            text
            @click="updateSale"
          >
            Guardar
          </v-btn>-->
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-data-table
      :headers="headers"
      :items="sales"
      class="elevation-1"
      :footer-props="{ 'items-per-page-options': [5, 10, 20, 50] }"
      :server-items-length="totalSales"
      :options.sync="options"
    >
      <template v-slot:item.state.name="{ item }">
        <v-chip
          :color="getColor(item.state.name)"
          dark
          x-small
          v-if="item.state.name === 'EMITIDO'"
        >
          Emitido
        </v-chip>
        <v-chip
          :color="getColor(item.state.name)"
          dark
          x-small
          v-if="item.state.name === 'ANULADO'"
        >
          Anulado
        </v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          class="mr-2"
          @click="getReport(item.id)"
        >
          mdi-file-pdf
        </v-icon>



       <!-- <v-icon
          small
          @click="deleteItem(item.id)"
        >
          mdi-delete
        </v-icon> -->
      </template>
      <template v-if="progress" v-slot:no-data>
        <div class="text-center">
          <v-progress-circular
            :size="40"
            :width="3"
            color="green"
            indeterminate
          ></v-progress-circular>
        </div>
      </template>
    </v-data-table>
    <div class="text-center ma-2">
      <v-snackbar
          v-model="snackbar1"
      >
        {{ text }}
        <template v-slot:action="{ attrs }">
          <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="snackbar1 = false"
          >
            Cerrar
          </v-btn>
        </template>
      </v-snackbar>
    </div>
    <div class="text-center ma-2">
      <v-snackbar
          v-model="snackbar2"
          color="lime darken-4"
      >
        {{ messages }}
        <template v-slot:action="{ attrs }">
          <v-btn
            color="with"
            text
            v-bind="attrs"
            @click="snackbar2 = false"
          >
            Cerrar
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
import {Errors} from "../../plugins/errors";
import TableEditSale from "../../components/tables/TableEditSale";
import FormNatural from "../../components/forms/FormNatural";
import FormLegal from "../../components/forms/FormLegal";
import {mapState} from "vuex";
import PDF from 'jspdf'
import 'jspdf-autotable';


export default {
  name: "Sales",
  components: {TableEditSale,FormLegal, FormNatural},
  data () {
    return {
      headers: [
        { text: 'FACTURA/BOLETA', value: 'type.name',                        sortable: false, class: "success white--text"},
        { text: 'NUMERO',         value: 'prenum',                           sortable: false, class: "success white--text"},
        { text: 'CLIENTE',        value: 'customer.person.dni',              sortable: false, class: "success white--text"},
        { text: 'NOMBRES',        value: 'customer.person.full_name',        sortable: false, class: "success white--text"},
        { text: 'FECHA',          value: 'date',                             sortable: false, class: "success white--text"},
        { text: 'SUBTOTAL',       value: 'subtotal',                         sortable: false, class: "success white--text"},
        { text: 'MONTO',          value: 'total',                            sortable: false, class: "success white--text"},
        { text: 'ESTADO',         value: 'state.name',                       sortable: false, class: "success white--text"},
        { text: 'RESPONSABLE',    value: 'responsable.full_name',            sortable: false, class: "success white--text"},
        { text: 'ACCIONES',       value: 'actions',                          sortable: false, class: "success white--text"},
      ],
      sales: [],
      detailSales: [],
      progress: false,
      dialog: false,
      showTableSale: false,
      text: 'Operación realizada con éxito !!!',
      messages: '',
      totalSales: 0,
      errors: new Errors(),
      listSales:[],
      listBuys: [],
      menu: false,
      snackbar1: false,
      snackbar2: false,
      options: {},
      editedItem: {
        customer: {
          person: {
            id: '',
            ruc: '',
            businessName: '',
            phone: '',
            email: ''
          },
          points: ''
        },
        type: {
          description: ''
        },
        prefijo: '',
        number: '',
        date: '',
        subtotal: '',
        total: '',
        igv: 0,
        type_ticket_invoice_id: null,
        state_ticket_invoice_id: null,
        cash_id: null,
        type_buy_id: null,
        entity_id: null,
        state: {
          description: ''
        },
        responsable: {
          full_name: ''
        },
        details: ''
      }
    }
  },
  computed: {
    ...mapState('auth', ['user'])
  },
  created() {
    this.getSales()
    this.listTypeTicketInvoice()
    this.listTypeBuy()
  },
  watch: {
    dni (search) {
      if (search.length === 8) {
        axios.get(`/api/assignments/filter/person?search=${search}`)
          .then(response => {
            if (response.data.data.length) {
              this.editedItem.person = response.data.data['0']
            }
          })
          .catch(error => console.log(error))
      }
    },
    ruc (search) {
      if (search.length === 11) {
        axios.get(`/api/assignments/filter/legal?search=${search}`)
          .then(response => {
            if (response.data.data.length) {
              this.editedItem.person = response.data.data['0']
            }
          })
          .catch(error => console.log(error))
      }
    },
    options: {
      async handler() {
        await this.getSales();
      }
    }
  },
  methods: {
    getSales () {
      const params = { ...this.options }
      this.progress = true
      axios.get('/api/ticket', {params})
        .then(response => {
          this.sales = response.data.data.data
          this.totalSales = response.data.data.total
          this.progress = false
        })
        .catch(error => console.log(error))
    },
    listTypeTicketInvoice () {
      axios.get('/api/ticket/invoices')
        .then(response => this.listSales = response.data.typeTicketInvoice)
        .catch(error => console.log(error))
    },
    listTypeBuy () {
      axios.get('/api/ticket/buys')
        .then(response => this.listBuys = response.data.typeBuy)
        .catch(error => console.log(error))
    },
    editItem (item) {
      this.editedItem = Object.assign({}, item)
      this.editedItem.customer.person.errors = new Errors()
      this.dialog = true
      this.showTableSale= true
    },
    updateSale () {
      let updateTicketInvoice = {
        'ruc'                     : this.editedItem.customer.person.ruc,
        'businessName'            : this.editedItem.customer.person.businessName,
        'phone'                   : this.editedItem.customer.person.phone,
        'email'                   : this.editedItem.customer.person.email,
        'customer'                : this.editedItem.customer.points,
        'customer_id'             : this.editedItem.customer_id,
        'person_id'               : this.editedItem.customer.person.id,
        'number'                  : this.editedItem.number,
        'date'                    : this.editedItem.date,
        'subtotal'                : this.editedItem.subtotal,
        'total'                   : this.editedItem.total,
        'type_ticket_invoice_id'  : this.editedItem.type_ticket_invoice_id,
        'state_ticket_invoice_id' : this.editedItem.state_ticket_invoice_id,
        'sales'                   : this.editedItem.details,
        'cash_id'                 : this.editedItem.cash_id,
        'type_buy_id'             : this.editedItem.type_buy_id,
        'area_assignment_id'      : this.user[0].id,
        'entity_id'               : this.user[0].area.entity.id
      }

      axios.put(`/api/ticket/${this.editedItem.id}`, updateTicketInvoice )
        .then(() => {
          this.getSales()
          this.close()
          this.snackbar = true;
        },error => this.errors.record(error.response.data.errors));
    },

    getReport (id) {
      setTimeout(() => {
        axios.get(`api/report/comprobanteVenta/${id}`)
            .then((response) => {
              this.listReport = response.data.details
              this.entity=response.data.entity
              this.responsable=response.data.responsable
              this.totalVentas=response.data.totalVentas
              this.createPDF()
            })
            .catch(error => console.log(error))
      }, 1000)
    },
    headRows() {
      return [{
        name: 'Producto',
        quantity: 'Cant.',
        sale_unit: 'P/U',
        total: 'Sub Total',
      }];
    },


    bodyRows(rowCount, pdfBody) {
      rowCount = rowCount || 10;
      let body = [];
      for (let i = 0; i < pdfBody.length; i++) {
        body.push({
          name: pdfBody[i].name,
          quantity: pdfBody[i].quantity,
          sale_unit: pdfBody[i].sale_unit,
          total: pdfBody[i].total,
        });
      }
      return body;
    },
    createPDF () {
      let doc = new PDF("p", "pt", [250, 650]);
      let header = () => {

        let header_1 = () => {
          doc.setFontSize(10);
          doc.text(115, 40,`BOTICA`)
          doc.text(85, 50, `${this.entity[0].name}`)
          doc.text(87, 60, `RUC : ${this.entity[0].ruc}`)
          doc.text(70, 70, `DIRECCION : ${this.entity[0].direction}`)
          doc.text(115, 100, `${this.listReport[0].type}`)
          doc.text(95, 110, `${this.listReport[0].prefijo+' - '+this.listReport[0].numero}`)

        };
        doc.autoTable({didDrawPage : header_1});

        let header_2 = () => {

          doc.setFontSize(5);
          doc.text(39, 50, '')
        };
        doc.autoTable({didDrawPage : header_2});

        let today = new Date();
        let header_3 = () => {
          doc.setFontSize(8);
          doc.text(80, 80, `Fecha impresion: ${today.getDate()<10 ? '0'+today.getDate() : today.getDate()}/${today.getMonth()<10 ? '0'+today.getMonth():today.getMonth()}/${today.getFullYear()<10 ? '0'+today.getFullYear():today.getFullYear()}`)
        };
        doc.autoTable({didDrawPage : header_3});

        let header_4 = () => {
          doc.setFontSize(8);
          doc.text(105, 90, `Hora: ${today.getHours()<10 ? '0'+today.getHours() : today.getHours()}:${today.getMinutes()<10 ? '0'+today.getMinutes():today.getMinutes()}:${today.getSeconds()<10 ? '0'+today.getSeconds():today.getSeconds()}`)
        };
        doc.autoTable({didDrawPage : header_4});

        let header_5 = () => {
          doc.setFontSize(6);
          doc.text(450, 50, `Página ${doc.internal.getNumberOfPages()}`);
        };
        doc.autoTable({didDrawPage : header_5});

        let header_6 = () => {
          doc.setFontSize(8);
          doc.text(70, 120, `FECHA VENTA: ${this.listReport[0].date}`)
          doc.text(39, 128, `RESPONSABLE:  ${this.responsable[0].firstName+' '+this.responsable[0].lastName}`)
        };
        doc.autoTable({didDrawPage : header_6});
        let header_7 = () => {
          doc.setFontSize(8);
          const number = parseFloat(this.totalVentas).toFixed(2);
          doc.text(50, 400, `TOTAL VENTAS:  ${number}`)

        };
        doc.autoTable({didDrawPage : header_7});

      };
      console.error = () => {};
      doc.autoTable({
        didDrawPage : header,
        margin: {top: 140, left:10, right: 10},
        styles: {
          cellPadding: 1,
          fontSize: 7,
          valign: 'middle',
          halign: 'center',

        },
        theme: 'grid',
        head: this.headRows(),
        body: this.bodyRows(this.countRow, this.listReport),


        bodyStyles: {
          margin: 5,
          fontSize: 7

        },
        columnStyles: {
          0: {cellWidth: 110},
          1: {cellWidth: 40, Valign: 'middle', halign: 'center'},
          2: {cellWidth: 40, valign: 'middle', halign: 'right'},
          3: {cellWidth: 40, valign: 'middle', halign: 'right'},

        }
      });

      doc.save(`ComprobanteVenta${Date.now()}.pdf`);
    },
    remove ($event) {
      let deleteItem = {
        'item'               : $event,
        'area_assignment_id' : this.user[0].id,
        'entity_id'          : this.user[0].area.entity.id
      }

      axios.post('/api/ticket/destroy', deleteItem)
        .then(() => {
          this.getSales()
          let index = this.editedItem.details.map(item => item.id).indexOf($event.id)
          this.editedItem.details.splice(index, 1)
        })
    },


 /*   remove (...args) {
      const [$event, number] = args
      let request = {
        'product_id': $event.product.id,
        'id': $event.id,
        'number': number
      }
        axios.post(`/api/ticket/destroy`,request)
          .then((response) => {
            this.getSales()
                if (response.data.code === 1) {
                  let index = this.editedItem.details.map(item => item.id).indexOf($event)
                  this.editedItem.details.splice(index, 1)
                }
            this.messages = response.data.message
            this.snackbar2 = true
          })

    },*/
    close () {
      this.dialog = false
      this.showTableSale = false
    },
    deleteItem (id) {
      axios.delete(`/api/ticket/invoice/${id}`)
        .then(() => {
          this.dialog = false
          this.getSales()
        }).catch(error => console.log(error))
    },
    changeDni (search) {
      this.dni = search
    },
    changeRuc (search) {
      this.ruc = search
    },
    getColor (state) {
      if (state === 'EMITIDO') return 'primary'
      else if (state === 'ANULADO') return 'red'
      else return ''
    },
  }
}
</script>

<style scoped>
.basil {
  background-color: #FFFBE6 !important;
}
.basil--text {
  color: #356859 !important;
}
.inputs-container,
.col-12 {
  padding-top: 0;
  padding-bottom: 0;
}
.theme--light.v-input {
  padding-top: 0;
}
.v-input__slot {
  margin-bottom: 0;
}
</style>
