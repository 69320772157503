<template>
  <tr>
    <td class="text-md-center">{{index + 1}}</td>
    <td v-if="item">
      <span>{{item.product.name}}</span>
    </td>
    <td v-if="item.product.laboratory">
      <span>{{item.product.laboratory.name}}</span>
    </td>
    <td v-else>
      <span></span>
    </td>
    <td>
      <v-text-field
        v-model="item.lot"
        outlined
        dense
        class="mt-6"
      ></v-text-field>
    </td>
    <td>
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="item.expiration_date"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="item.expiration_date"
            readonly
            outlined
            dense
            class="mt-6"
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="item.expiration_date"
          type="month"
          no-title
          scrollable
          color="green lighten-1"
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="success"
            @click="menu = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            text
            color="success"
            @click="$refs.menu.save(item.expiration_date)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
    </td>

    <td v-if="item.product.box_quantity > 1">
      <span v-if="!editable">{{ item.quantity }}</span>
      <v-text-field
          v-else
          v-model="item.quantity"
          outlined
          dense
          class="mt-6"
          ref="qua"
          v-uppercase
          @keypress.enter="changeQuantity(item.quantity)"
          @input="up"
      ></v-text-field>
    </td>
    <td v-else>
      <span v-if="!editable">{{ item.quantity }}</span>
      <v-text-field
          v-else
          v-model="item.quantity"
          outlined
          dense
          class="mt-6"
          @input="changeQuantity(item.quantity)"
          type="number"
          min="1"
          step="1"
          ref="qua"
      ></v-text-field>
    </td>
    <td v-if="item">
      <span v-if="!editable">{{ item.sale_unit  }}</span>
      <v-text-field
          v-else
          v-model="item.sale_unit"
          outlined
          dense
          :label="item.unit"
          @input="saleUnit()"
        class="mt-6"
      ></v-text-field>
    </td>
 <!--   <td v-if="item">
      <span v-if="!editable">{{ item.blister  }}</span>
      <v-text-field
          v-else
          v-model="item.sale_blister"
          outlined
          dense
          :label="item.blister"
          @input="saleBlister()"
          class="mt-6"
      ></v-text-field>
    </td>
    <td v-if="item">
      <span v-if="!editable">{{ item.box }}</span>
      <v-text-field
          v-else
          v-model="item.sale_box"
          outlined
          dense
          :label="item.box"
          @input="saleBox()"
          class="mt-6"
      ></v-text-field>
    </td>-->
    <td v-if="item.total === totalPartial">
      <span>{{ totalPartial | currency }}</span>
    </td>
    <td v-else>
      <span>{{ item.total | currency }}</span>
    </td>
    <td class="text-md-center">
      <v-icon
        small
        class="mr-2"
        @click="$emit('removeList', item)"
      >
        {{ icons.mdiDelete }}
      </v-icon>
    </td>
  </tr>
</template>
<script>
import {mdiAccount, mdiCheckBold, mdiDelete, mdiPencil, mdiShareVariant,} from '@mdi/js'

export default {
  name: "EditRowSale",
  props: ['item', 'index'],
  data() {
    return {
      icons: {
        mdiAccount,
        mdiPencil,
        mdiShareVariant,
        mdiDelete,
        mdiCheckBold
      },
      editable: false,
      menu: false,
      modal: false,
      totalPartial: 0.0,
      message: '',
      dialogDelete: false,
      dialog: false
    }
  },
  computed: {
    saleUnit: {
      get () {
        return this.item ? this.item.sale_unit : 0.0
      },
      set (value) {
        this.item.sale_unit = value
      }
    },
    quantity: {
      get () {
        return this.item.quantity
      },
      set (value) {
        this.item.quantity = value
      }
    },
    total: {
      get () {
        return this.item.total
      },
      set (value) {
        this.item.total = value
      }
    }
  },
  watch: {

    total () {
      this.$emit('total')
    },
    quantity () {
      this.changeQuantity(this.item.quantity)
    }

  },

  mounted() {
    this.toggleEditable()
  },
  methods: {
    toggleEditable() {
      this.editable = !this.editable;
    },
    changeQuantity (val) {
      console.log(val)
      let valFirst = 0
      let valLast  = 0
      let valFirst1 = 0
      let valLast1 =0
      let valFirst2 = 0
      let valLast2 =0
      let quotient = 0
      let rest     = 0
      let productStockt = this.item.stock.stock
      let quantity = this.item.quantity

      if (val.toString().includes("F")) {
        const valuesproductStockt = productStockt.split("F")
        for (let i = 0; i < valuesproductStockt.length; i++) {
          valFirst1 = valuesproductStockt[0]
          valLast1  = valuesproductStockt[1]

        }

        const valuesquantity=quantity.toString().split("F")
        for (let i = 0; i < valuesquantity.length; i++) {
          valFirst2 = valuesquantity[0]
          valLast2  = valuesquantity[1]
        }

        if (parseInt(valLast2)>=this.item.box_quantity) {
          quantity=(parseInt(valFirst2)+1)+'f'+ (parseInt(valLast2)-parseInt(this.item.box_quantity))
        }
        const values = val.toString().split("F")
        for (let i = 0; i < values.length; i++) {
          valFirst = values[0]
          valLast = values[1]
        }
        console.log(values.length)

        quantity=(parseInt(valFirst2) * parseFloat(this.item.box_quantity))+parseInt(valLast2)
        if (quantity) {
          productStockt=(parseInt(valFirst1) * parseFloat(this.item.box_quantity))+parseInt(valLast1)
          if(productStockt>=quantity) {
            this.item.box = (parseInt(valFirst) * parseFloat(this.item.sale_box)).toFixed(2)
            if(parseInt(this.item.blister_quantity)===parseInt(this.item.presentation_sale)) {
              this.item.unit    = (parseInt(valLast) * parseFloat(this.item.sale_unit)).toFixed(2)
            }else if (parseInt(valLast) >= parseInt(this.item.presentation_sale)) {
              quotient = parseInt(valLast) % parseInt(this.item.blister_quantity)
              rest  = (parseInt(valLast) - parseInt(quotient)) / parseInt(this.item.blister_quantity)
              this.item.blister = (parseInt(rest) * parseFloat(this.item.sale_blister)).toFixed(2)
              this.item.unit    = (parseInt(quotient) * parseFloat(this.item.sale_unit)).toFixed(2)
            }else {
              this.item.blister = (parseFloat(0) * parseFloat(this.item.sale_blister)).toFixed(2)
              this.item.unit    = (parseInt(valLast) * parseFloat(this.item.sale_unit)).toFixed(2)
            }
            this.item.total = parseFloat(this.item.box ) + parseFloat(this.item.blister) + parseFloat(this.item.unit )
            this.totalPartial = this.item.total
          }else {
            this.dialog=true
          }
        }
      }else {
        if(productStockt>=quantity){
          this.item.box     = parseFloat(0).toFixed(2)
          this.item.blister = parseFloat(0).toFixed(2)
          this.item.unit    = (parseInt(val) * parseFloat(this.item.sale_unit)).toFixed(2)
          this.item.total   = parseFloat(this.item.box ) + parseFloat(this.item.blister) + parseFloat(this.item.unit )
          this.totalPartial = this.item.total
        }else {
          this.dialog=true
        }
      }
      this.$emit('dataRow', this.item)
    },

    up (){
      if (parseInt(this.item.quantity) > -1 && !this.item.quantity.includes('F')) {
        this.$nextTick(() => {
          this.item.quantity =  parseInt(this.item.quantity) + 'F0'
        })
      }
      if (this.item.quantity.includes('F')) {
        let valFirst = 0
        let valLast  = 0
        let resto = 0
        let cocient = 0


        const value=this.item.quantity.split("F")
        for (let i = 0; i < this.item.quantity.length; i++) {
          valFirst = value[0]
          valLast  = value[1]
        }



        if (valFirst>0) {
          if(valLast>=this.item.box_quantity) {
            resto = parseInt(valLast)%this.item.box_quantity
            cocient = parseInt(Math.floor(valLast / this.item.box_quantity )) + parseInt(valFirst)
            this.item.quantity =  cocient +'F'+ resto
          }
        }else {
          if(valLast>=this.item.box_quantity) {
            resto = parseInt(valLast)%this.item.box_quantity
            valFirst = Math.floor(valLast / this.item.box_quantity )
            this.item.quantity =  valFirst +'F'+ resto
          }
        }
      }
    }
  },
  removeItem () {
    this.dialogDelete = true
  },
  remove () {
    this.$emit('removeList', this.item.id)
  },
  closeDelete () {
    this.dialogDelete = false
  },
  closeDialog () {
    this.dialog = false
    this.quantity=''
  },
  deleteItemConfirm () {
    this.remove()
    this.closeDelete()
  },
  focusInput () {
    this.$refs.qua.focus()
  },
}
</script>
<style scoped>
.basil {
  background-color: #FFFBE6 !important;
}
.basil--text {
  color: #356859 !important;
}
.inputs-container,
.col-12 {
  padding-top: 0;
  padding-bottom: 0;
}
.theme--light.v-input {
  padding-top: 0;
}
.v-input__slot {
  margin-bottom: 0;
}
</style>
